import React, { useContext, useEffect, useRef, useState } from 'react';
import * as Styled from './OxTextArea.styled';
import { ThemedInputContext } from 'src/context/ThemedInputContext';

type TProps = {
    onValueChange: (val: string) => void;
    showCount?: boolean;
};

export const OxTextArea = React.forwardRef<HTMLTextAreaElement, SCProps<'textarea', TProps>>(
    (
        {
            className,
            placeholder,
            value,
            maxLength,
            rows,
            cols,
            onBlur,
            onValueChange,
            showCount,
            ...props
        }: SCProps<'textarea', unknown>,
        ref
    ): JSX.Element => {
        const themeContext = useContext(ThemedInputContext);
        let textAreaRef = ref;
        if (!ref) {
            textAreaRef = useRef<HTMLTextAreaElement>(null);
        }
        const [isValid, setIsValid] = useState(true);
        const [touched, setTouched] = useState(false);
        const [validationMessage, setValidationMessage] = useState('');

        const textAreaElement = (textAreaRef as React.RefObject<HTMLTextAreaElement>).current;

        const getNativeErrorMsg = (): string | undefined => textAreaElement?.validationMessage;

        const onTextAreaChange = (): void => {
            const errMsg = getNativeErrorMsg();
            const valid = textAreaElement?.validity.valid;

            if (errMsg !== validationMessage) setValidationMessage(errMsg || '');
            if (valid !== isValid) setIsValid(!!valid);
            onValueChange && onValueChange(textAreaElement?.value);
        };

        const onInputBlur = (e: React.FocusEvent<HTMLTextAreaElement>): void => {
            const errMsg = getNativeErrorMsg();
            if (errMsg !== validationMessage) setValidationMessage(errMsg || '');
            setTouched(true);
            onBlur && onBlur(e);
        };

        useEffect(() => {
            setIsValid(!!textAreaElement?.validity.valid);
        }, [textAreaElement]);

        return (
            <Styled.Container className={className}>
                <Styled.TextArea
                    ref={textAreaRef}
                    placeholder={placeholder}
                    onChange={onTextAreaChange}
                    value={value}
                    maxLength={maxLength}
                    rows={rows}
                    cols={cols}
                    customTheme={themeContext}
                    onBlur={onInputBlur}
                    {...props}
                />
                {showCount && (
                    <Styled.Count customTheme={themeContext}>{value.length}</Styled.Count>
                )}
                {!isValid && touched && validationMessage && (
                    <Styled.Error>{validationMessage}</Styled.Error>
                )}
            </Styled.Container>
        );
    }
);
