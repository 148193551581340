import { EFontWeight, EFonts } from 'src/config/enums';
import styled, { css } from 'styled-components';

import { TThemedInputContext } from 'src/context/ThemedInputContext';
import { createTextStyle } from 'src/utils/createTextStyle';
import { fluidSizing } from 'src/utils';

export const Container = styled.div`
    position: relative;
`;

export const TextArea = styled.textarea<{ customTheme: TThemedInputContext }>(
    ({ customTheme }) => css`
        width: 100%;
        resize: none;
        border-width: 1px;
        border-style: solid;
        color: ${customTheme.color};
        border-color: ${customTheme.borderColor};
        background-color: ${customTheme.backgroundColor};
        transition: 200ms;
        -webkit-appearance: none;
        ${createTextStyle(EFonts.Montserrat, EFontWeight.Medium, [10, 10, 10], [18, 18, 18])}
        ${fluidSizing([
            { prop: 'height', values: [79, 79, 79] },
            { prop: 'border-radius', values: [15, 15, 15] },
            { prop: 'padding-left', values: [15, 15, 15] },
            { prop: 'padding-right', values: [15, 15, 15] },
            { prop: 'padding-top', values: [4, 4, 4] }
        ])}
      ::placeholder {
            color: ${customTheme.color};
        }
        &:invalid {
            box-shadow: none;
        }

        @media print {
            font-size: 1.6rem;
            height: 14rem;
            padding-left: 2rem;
            padding-right: 2rem;
        }
    `
);

export const Count = styled.div(
    ({ customTheme }) => css`
        bottom: 0;
        color: ${customTheme.color};
        position: absolute;
        right: 0;

        ${fluidSizing([
            { prop: 'bottom', values: [7.5, 7.5, 7.5] },
            { prop: 'right', values: [7.5, 7.5, 7.5] }
        ])}

        ${createTextStyle(EFonts.Montserrat, EFontWeight.Medium, [10, 10, 10], [18, 18, 18])}
    `
);

export const Error = styled.div(
    () => css`
        position: absolute;
        width: 100%;
        text-align: center;
        top: 100%;
        ${fluidSizing([{ prop: 'line-height', values: [16, 16, 16] }])};
        ${createTextStyle(EFonts.Montserrat, EFontWeight.Medium, [8, 8, 8], [14, 14, 14])}
    `
);
